import Bg from '../../assets/ingeeniar/home/fondo-home.png';
import Bg2 from '../../assets/ingeeniar/home/fondo2-home.png';
import Bg3 from '../../assets/ingeeniar/home/fondo3-home.png';
import Card1 from '../../assets/ingeeniar/home/desarrollo-app.svg';
import Card2 from '../../assets/ingeeniar/home/soluciones-paytopay.svg';
import Card3 from '../../assets/ingeeniar/home/soluciones-gate-way.svg';
import Card4 from '../../assets/ingeeniar/home/posicionamiento-web.svg';
import Card5 from '../../assets/ingeeniar/home/srevicios-cloud.svg';
import HombrePC from '../../assets/ingeeniar/home/hombre-pc.svg';
import Config from '../../helpers/Config';


const App=()=>{
  return  <>
            <div className="container-fluid bg-top min-height" style={{backgroundImage: 'url("'+Bg+'")',backgroundSize:'cover',backgroundPosition:'center',position:'relative',zIndex:'9'}} >
              <div className="row">
                <div className="col">
                  <div className="p-md-5 text-center slider">
                    <div className="text-white pt-5">
                      <h2 className="pt-4"><b>Apasionados</b></h2>
                      <h3>por el software</h3>
                      <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                        Contáctenos
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid container2-home" style={{backgroundImage: 'url("'+Bg2+'")'}}>
              <div className="row col-lg-10 m-auto">
                <div className="col-md-6">
                  <div className="container-hombre-pc">
                    <img src={HombrePC} className="hombre-pc"/>
                  </div>
                  <h1 className="text-azul title-continer2-home"><b>Sobre</b></h1>
                  <h1 className="text-azul subtitle-continer2-home">nosotros</h1>
                </div>
                <div className="col-md-6 p-md-5 text-muted text-regular">
                  <p className="parrafo-container2-home"><b>Somos una integradora multiempresa del campo de la ingeniería social, la programación y el posicionamiento SEO creada en latinoamérica</b>, nacimos en la ciudad de Pereira (Risaralda) Colombia y rapidamente nos convertimos en un punto de referencia de la industria de los servicios informáticos especializados.</p>
                  <p className="parrafo-container2-home mt-4"><b>Nuestro sistema IIS (Social Engineering interaction™)</b> es el tercer sistema de posicionamiento Web bajo demanda más utilizado por plataformas retail de Hispanoamérica, de la mano de nuestro partners para el año 2020 proyectamos generar 5,45 millones de visitas dirigidas al día.</p>
                </div>
              </div>
            </div>
            <div className="container-fluid p-md-5 min-height-servicios-home" style={{backgroundImage: 'url("'+Bg3+'")'}}>
              <div className="text-center">
                <h1 className="text-azul title-servicios-home">Servicios</h1>
              </div>
              <div className="col-md-9 p-0 m-auto cards-nosotros">
                <div className="row mt-5 mb-5">
                  <div className="col-lg-4">
                    <div className="my-card text-center">
                      <img src={Card1} className="img-card-nosotros"/>
                      <div class="text-card mt-5">
                        <h3 class="title-card"><b>Desarrollo de</b></h3>
                        <h3>aplicativos</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="my-card text-center">
                      <img src={Card2} className="img-card-nosotros"/>
                      <div class="text-card mt-5">
                        <h3 class="title-card"><b>Soluciones</b></h3>
                        <h3>pay to pay</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="my-card text-center">
                      <img src={Card3} className="img-card-nosotros"/>
                      <div class="text-card mt-5">
                        <h3 class="title-card"><b>Soluciones</b></h3>
                        <h3>Gateway</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 ml-auto">
                    <div className="my-card text-center">
                      <img src={Card4} className="img-card-nosotros"/>
                      <div class="text-card mt-5">
                        <h3 class="title-card"><b>Posicionamiento</b></h3><h3>Web (SEO)</h3>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 mr-auto">
                    <div className="my-card text-center">
                      <img src={Card5} className="img-card-nosotros"/>
                      <div class="text-card mt-5">
                        <h3 class="title-card"><b>Servicios</b></h3>
                        <h3>Cloud</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
}

export default App