import React,{useState,useEffect} from 'react';
import Out from "./app/Out";

const App=()=>{

  return <Out/>

}

export default App;
