import Bg from '../../assets/ingeeniar/servicios/fondo-servicios.png';
import Bg2 from '../../assets/ingeeniar/servicios/computador.png';
import Bg3 from '../../assets/ingeeniar/servicios/desarrollo-aplicativos-servicios.svg';
import Bg4 from '../../assets/ingeeniar/servicios/soluciones-gateway-servicios.svg';
import Bg5 from '../../assets/ingeeniar/servicios/soluciones-paytopay-servicios.svg';
import Bg6 from '../../assets/ingeeniar/servicios/psocionamiento-web-servicios.svg';
import Bg7 from '../../assets/ingeeniar/servicios/cloud-servicio.svg';
import Bg8 from '../../assets/ingeeniar/servicios/ciudad.png';
import Config from '../../helpers/Config';

const App=()=>{
  return  <>
            <div className="container-fluid bg-top min-height" style={{backgroundImage: 'url("'+Bg+'")',backgroundSize:'cover',backgroundPosition:'center',position:'relative',zIndex:'9'}} >
              <div className="row">
                <div className="col-lg-7 col-md-10 m-auto">
                  <div className="p-md-5 text-center slider">
                    <div className="text-white pt-5 text-intro">
                      <h2 className="mb-0 pt-4"><b>Servicios</b></h2>
                      <h1 className="h1 mt-3">conoce nuestros servicios complementarios</h1>
                      <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                        Contáctenos
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid mb-5 position-relative">
              <div className="row p-lg-4 ml-lg-5 mr-lg-5 ajustar">
                  <div className="col-lg-6 d-lg-none d-xl-none pr-0">
                    <img src={Bg2} className="computador-servicios2" alt="pagoslocales"/>
                  </div>
                  <div className="col-lg-6 mr-auto pl-lg-5 text-muted">
                    <div className="text-card mt-3">
                        <p className="h5">Como integradora multiempresarial contamos con uno de los portafolios más amplios y diversos de la industria, hoy prestamos a nuestros clientes más de 117 servicios y 11 productos diferentes, nuestro campo de acción nos permite llegar a cualquier País del mundo donde se pueda garantizar una conexión a Internet con ancho de banda superior a 3,5 megas de bajada.</p>
                        <p className="h5 mt-3">Sin embargo ninguno de nuestros servicios o productos se encuentran limitados, nuestra promesa de venta nos permite hoy garantizar que el 100% de estos pueden ser modificados con el fin de adaptarlos a las necesidades de nuestros clientes y consumidores finales.</p>
                    </div>
                  </div>
              </div>
              <div className="row p-lg-4 ml-lg-5 mr-lg-5">
                <div className="col-lg-6 text-center">
                  <img src={Bg3} className="img-fluid w-100" alt="pagoslocales"/>
                </div>
                <div className="col-lg-6 p-md-5 mt-5">
                  <h1 className="title-section m-0 p-0" style={{lineHeight:'3.4rem'}}><b>Desarrollo</b><br/>de aplicativos</h1>
                  <div className="text-card mt-3 text-muted text-justify">
                    <h5  className="pt-4">Desarrollo de aplicativos. Contamos con experiencia en desarrollo de código multiplataforma, lo que nos permite garantizar que llegaremos con éxito al desarrollo de sus necesidades, ¡garantizamos un código a la medida de su empresa.. amamos el código! </h5>
                  </div>
                  <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                    Contáctenos
                  </a>
                </div>
              </div>
              <div className="row p-lg-4 ml-lg-5 mr-lg-5">
                <div className="col-lg-6 text-center d-block d-lg-none">
                  <img src={Bg4} className="img-fluid w-100" alt="pagoslocales"/>
                </div>
                <div className="col-lg-6 p-md-5 mt-5">
                  <h1 className="title-section pt-4 m-0 p-0" style={{lineHeight:'3.4rem'}}><b>Soluciones</b><br/>Gateway</h1>
                  <div className="text-card mt-3">
                    <div className="text-card mt-3 text-muted text-justify">
                      <h5  className="pt-4">Desarrollo de aplicativos. Contamos con experiencia en desarrollo de código multiplataforma, lo que nos permite garantizar que llegaremos con éxito al desarrollo de sus necesidades, ¡garantizamos un código a la medida de su empresa.. amamos el código! </h5>
                    </div>
                    <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                      Contáctenos
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 text-center d-none d-lg-block">
                  <img src={Bg4} className="img-fluid w-100" alt="pagoslocales"/>
                </div>
              </div>
              <div className="row p-lg-4 ml-lg-5 mr-lg-5">
                <div className="col-lg-6 text-center">
                  <img src={Bg5} className="img-fluid w-100" alt="pagoslocales"/>
                </div>
                <div className="col-lg-6 pt-5">
                  <h1 className="title-section m-0 p-0" style={{lineHeight:'3.4rem'}}><b>Soluciones</b><br/>pay to pay</h1>
                  <div className="text-card mt-3 text-muted text-justify">
                    <h5 className="pt-5">Nos especializamos en proceso BPO pay to pay, lo que nos permite garantizar operaciones de pagos y plataformas transaccionales en América Latina, con tarifas sorprendentes y líneas de avance a clientes premium. </h5>
                  </div>
                  <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                    Contáctenos
                  </a>
                </div>
              </div>
              <div className="row p-lg-4 ml-lg-5 mr-lg-5">
                  <div className="col-lg-6 text-center pt-5 d-block d-lg-none">
                    <img src={Bg6} className="img-fluid w-100" alt="pagoslocales"/>
                  </div>
                  <div className="col-lg-6 pt-5 pl-lg-5">
                    <h1 className="title-section pt-4 m-0 p-0" style={{lineHeight:'3.4rem'}}><b>Posicionamiento</b><br/>Web (SEO)</h1>
                    <div className="text-card mt-3 text-muted text-justify">
                      <h5 className="">Nos especializamos en banner Ads, PopUnders y Mobile Traffic, los anunciantes confían en Ingeniar™ para brindar tráfico de calidad y con la mejor protección contra el fraude. Direct Buy o Run of Network, hay una solución para todos. </h5>
                    </div>
                    <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                      Contáctenos
                    </a>
                  </div>
                  <div className="col-lg-6 text-center pt-5 d-none d-lg-block">
                    <img src={Bg6} className="img-fluid w-100" alt="pagoslocales"/>
                  </div>
              </div>
              <div className="row pl-lg-5 pr-lg-5 ml-lg-5 mr-lg-5">
                <div className="col-lg-6 text-center pt-5">
                  <img src={Bg7} className="img-fluid w-100" alt="pagoslocales"/>
                </div>
                <div className="col-lg-6 pt-5">
                  <h1 className="title-section m-0 p-0" style={{lineHeight:'3.4rem'}}><b>Servicios</b><br/>Cloud</h1>
                  <div className="text-card mt-3 text-muted text-justify">
                    <h5 className="pt-5">Contamos con robustos equipos que nos permite garantizar una redundancia del 99,99% a nuestros clientes, todos nuestros servidores de hosting son servidores Intel Xeon, desde 8 a 96GB de memoria RAM y discos duros SSD hasta 120 Teras clase Enterprise conectados mediante RAID 1 y RAID 10 incluyendo tarjetas de red de 1Gbps lo que permite una rápida conectividad para brindar los servicios y la realización de las copias de seguridad remotas, sin medición de ancho de banda. </h5>
                  </div>
                  <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-4">
                    Contáctenos
                  </a>
                </div>
              </div>
              <img src={Bg2} className="computador-servicios" alt="pagoslocales"/>
            </div>
            <div className="continer-fluid ciudad">
              <img src={Bg8} className="w-100" />
            </div>
          </>
}

export default App