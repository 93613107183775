import React from 'react';
import {Navbar,Nav} from 'react-bootstrap';
import {useScrollYPosition} from 'react-use-scroll-position';
import Config from '../../helpers/Config';
import Functions from '../../helpers/Functions';
import logo from '../../assets/ingeeniar/header/logo-menu.svg';
import facebook from '../../assets/ingeeniar/header/fb.svg'
import instagram from '../../assets/ingeeniar/header/ig.svg'
import twiter from '../../assets/ingeeniar/header/tw.svg'

const App=(props)=>{
  const scrollY   = useScrollYPosition();

  return  <Navbar expand="lg" bg="dark2" variant={scrollY<12?"dark nav-text-normal":"dark nav-text-white"} fixed="top">
            <Navbar.Brand href="#home" className="pl-5 pr-5">
              <img src={logo} alt="pgrw" className="logo pt-4"/>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav prueba" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto mr-5 parent navbar-nav">
                <Nav.Link href={Config.ConfigAppUrl} className={Functions.segment(1)===''?"border-primary-pgrw":""}>
                  Home
                </Nav.Link>
                <Nav.Link href={Config.ConfigAppUrl+"quienes_somos"} className={Functions.segment(1)==='quienes_somos'?"border-primary-pgrw":""}>
                  ¿Quiénes somos?
                </Nav.Link>
                <Nav.Link className={Functions.segment(1)==='beneficios'?"border-primary-pgrw":""} href={Config.ConfigAppUrl+"beneficios"}>Servicios</Nav.Link>
                <Nav.Link className={Functions.segment(1)==='contactenos'?"border-primary-pgrw":""} href={Config.ConfigAppUrl+"contactenos"}>Contáctenos</Nav.Link>
              </Nav>
              <Nav className="d-none d-md-flex mr-auto">
                <a className="nav-link pl-0 pr-0" href="https://www.facebook.com/ingeeniar/" target="_blank">
                  <img className="redes" src={facebook} alt="facebook"/>
                </a>
                <a className="nav-link pl-0 pr-0" href="https://www.instagram.com/ingeeniar/" eventKey={3} target="_blank">
                  <img className="redes" src={instagram} alt="instagram"/>
                </a>
                <a className="nav-link pl-0 pr-0" href="https://twitter.com/ingeeniar" eventKey={3} target="_blank">
                  <img className="redes" src={twiter} alt="twitter"/>
                </a>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
}
export default App