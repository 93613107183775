import Bg from  '../../assets/ingeeniar/quienes_somos/fondo.png';
import Bg2 from '../../assets/ingeeniar/quienes_somos/fondo-quienes-somos.png';
import Bg3 from '../../assets/ingeeniar/quienes_somos/fondo-3-quienes-somos.png';
import Bg4 from '../../assets/ingeeniar/quienes_somos/quienes-somos.svg';
import Config from '../../helpers/Config';

const App=()=>{
  return  <>
            <div className="container-fluid bg-top min-height" style={{backgroundImage: 'url("'+Bg+'")',backgroundSize:'cover',backgroundPosition:'center',position:'relative',zIndex:'9'}} >
              <div className="row">
                <div className="col">
                  <div className="p-5 text-center slider">
                    <div className="text-white pt-5">
                      <h2 className="mb-5 pt-4"><b>¿Quienes Somos?</b></h2>
                      <a href={Config.ConfigAppUrl+"contactenos"} className="btn btn-primary btn-lg mt-5">
                        Contáctenos
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid position-relative" style={{backgroundImage: 'url("'+Bg2+'")',backgroundSize:'cover',paddingTop:'60px',backgroundRepeat:'no-repeat'}}>
              <div className="col-lg-9 offset-lg-1 p-md-5">
                <div className='col-lg-12'>
                  <p className="text-muted text-justify h5">En Ingeeniar™ Amamos lo que hacemos y nos comprometemos de lleno con cada uno de nuestros proyectos, pero no nos limitamos a sólo funcionalidad, en esto marcamos la diferencia, nuestros productos no sólo son potentes, también son bellos, creemos fielmente en la idea de que un aplicativo debe ir más allá de la funcionalidad, siendo amigable con el usuario y con el entorno que busca una solución informática, sabemos que no somos una agencia de publicidad, pero debemos volar nuestra imaginación para entregar a nuestros clientes software con un nivel de estética digno de una empresa.</p>
                </div>
                <div className="col-lg-10 mt-5">
                  <p className="text-muted text-justify h5"><b>Nuestro eslogan ¡By your side! en español ¡A tu lado!,</b> es nuestra mayor promesa de venta, entender a nuestros clientes se nos da muy bien, con el paso de los años hemos entendido lo fundamental que resulta acompañar a cada uno de nuestros no sólo en el código, este si bien lo es todo para nosotros, es sólo un todo de los muchos elementos a contemplar dentro de lo que cada empresario busca para su empresa, en momentos donde el tiempo es un privilegio valioso, nos damos a la tarea de no desperdiciar el de nuestros clientes, por ello hacemos un profundo análisis de sus necesidades, garantizando de esta manera que estaremos a la altura de sus necesidades.</p>
                </div>
              </div>
            </div>
            <div className="container-fluid p-movil" style={{backgroundImage: 'url("'+Bg3+'")',backgroundRepeat:'no-repeat',backgroundSize:'cover',backgroundPosition:'center',paddingTop:'240px',minHeight:'1716px',position:'relative',zIndex:'99',marginBottom:'178px'}}>
              <div className="text-center">
                <h1 className="title-quienes-somos text-azul"><b>¿Quiénes</b></h1>
                <h1 className="title-quienes-somos2 text-azul">somos?</h1>
              </div>
              <div className="col-lg-9 m-auto text-muted text-justify">
                <p className='p-md-3 h5'>Ingeniar™ es una de las integradoras <b>multiempresas del campo de la ingeniería social, la programación y el posicionamiento SEO más importantes de América Latina,</b> nacimos en el 2008 en la ciudad de Pereira (Risaralda) Colombia, hoy estamos presentes gracias a nuestros partners de internacionalización en Brasil, Chile, Argentina, México y España desde donde atendemos operaciones a más de 45 clientes en 9 Países del mundo.</p>
                <p className='p-md-3 h5'>Todos nuestros sistemas hoy son implementados por medio del Sistema de Gestión a la Calidad ISO 9001 Versión 2008 e ISO 27001 Versión 2013.</p>
                <div className="row">
                  <div className="col-md-6">
                    <img src={Bg4} className="w-100" />
                  </div>
                  <div className="col-md-6">
                    <p className="h5"><b>Nuestros proyectos son desarrollados por la más alta ingeniería 100% Colombiana, Ingeniar™</b> tiene como lineamiento empresarial emplear más del 90% de su personal Administrativo y Operativo con mano de obra formada en Colombia, la inversión de sus acciones y capitales de participación así lo demuestran al ser accionista de importantes integradoras tecnológicas donde genera 56 fuentes de trabajo directos, ¡bienvenidos a una empresa de pujanza Paisa con proyección mundial!.</p>
                    <p className="pt-3 h5">Hoy orgullosamente somos parte de la comunidad ParqueSoft® Risaralda, en dónde hemos establecido una unidad de programación avanzada para nuestro proyecto JobERP®, así mismo contamos con sedes propias en la ciudades de Medellín, Bogotá, Cali y Manizales.</p>
                  </div>
                </div>
              </div>
            </div>

          </>
}

export default App
