function segment(num){
    let pathname,segment;
    if (num==="all") {
      pathname      =   window.location.pathname;
      segment       =   pathname.split('/');
      return segment;
    }
    if (num===undefined) {
      num=1
    }
    pathname      =   window.location.pathname;
    segment       =   pathname.split('/');
    let last          =   (segment.length - num);
    return segment[last];
  }
  
  /*const PostAsyncJson =  async (modulo,m,objeto,context,callback) =>  {
    let headers   =   new Headers();
    let data      =   new FormData();
    let lang      =   "es";
  
    Object.entries(objeto).map((v,k) => {
      return data.append (v[0],v[1]);
    })
  
  
    data.append ("lang",lang);
    data.append ("method", "post");
    data.append ("PUBLIC_KEY", process.env.REACT_APP_PUBLIC_KEY);
    data.append ("REACT_APP_PRIVATE_KEY", process.env.REACT_APP_PRIVATE_KEY);
  
    if (Store.get("security")===null) {
      data.append ("PRIVATE_KEY", Config.PRIVATE_KEY);
    }else {
      data.append ("tokens_access",Store.get("security"));
    }
  
    if (callback!==undefined) {
        data.append ("callback",callback.name);
    }
  
    let cabecera  =   {
                        headers:headers,
                        method: "POST",
                        body: data,
                        credentials: "same-origin"
                      }
  
    try {
      const response    =   await fetch(Config.ConfigApirest + modulo+"/"+m,cabecera);
      const json        =   await response.json();
      if (json.response!==undefined && json.response.callback!==undefined) {
        callback.funct(json)
      }
      return json;
    }catch (error) {
      console.log(error);
      return error;
    }
  }*/

  const exportar ={segment}
  export default exportar