//import './../../assets/bellecolombia/footer/css/fontello.css';
import Bg_footer from '../../assets/ingeeniar/home/footer.png';
import Logo_footer from '../../assets/ingeeniar/home/logo-blanco.svg';
import Icon1 from '../../assets/ingeeniar/home/correo.svg';
import Icon2 from '../../assets/ingeeniar/home/telefono.svg';
import Whatsapp from '../../assets/ingeeniar/home/Whatsapp.svg';

const App=()=>{
  return  <><a href='https://api.whatsapp.com/send?phone=573046749323' target="_blank"><img src={Whatsapp} alt="whatsapp" className="whatsapp" /></a>
          <footer style={{backgroundImage:'url('+Bg_footer+')'}}>
            <div className="w-100 text-center">
              <img src={Logo_footer} alt="pgrw" className="logo-footer"/>
            </div>
            <div className="w-100 text-center text-white mt-3">
              <div className="d-flex justify-content-center">
                <img src={Icon1} className="icon-footer mr-1" /> <span className="pr-4">3026179562</span>
                <img src={Icon2} className="icon-footer mr-1" /> <span>info@ingeeniar.com</span>
              </div>
            </div>
            <div className="w-100 text-center mt-2 text-white"><small> Copyright 2021 Ingeeniar S.A. | Credits | Politica de Privacidad</small></div>
          </footer></>
}

export default App
