import React,{useState} from 'react';
import Bg from '../../assets/ingeeniar/contactenos/fondo-contactenos.png';
import Bg2 from '../../assets/ingeeniar/contactenos/fondo-contacto.png';
import icon1 from '../../assets/ingeeniar/contactenos/correo.svg';
import icon2 from '../../assets/ingeeniar/contactenos/telefono.svg';
import icon3 from '../../assets/ingeeniar/contactenos/ubicacion.svg';
//import Functions from '../../helpers/Functions';


const App=()=>{
  const [inputs, setInputs] = useState({});

  const onChange=(e)=>{
    let input = {...inputs}
        input[e.target.name]  = e.target.value
        setInputs(input);
  }

  function onSubmit(e){
    e.preventDefault()
    let data  = inputs
    /*Functions.PostAsync("Contacto","contactenos",data,{},{name:"callbackContinue",funct:callbackContinue})*/
  }

  function callbackContinue(data){

  }

  return  <>
            <div className="container-fluid bg-top min-height" style={{backgroundImage: 'url("'+Bg+'")',backgroundSize:'cover',backgroundPosition:'center',position:'relative',zIndex:'9'}} >
              <div className="row">
                <div className="col-md-8 m-auto">
                  <div className="p-md-5 text-center slider">
                    <div className="text-white">
                      <h2 className="title-contactenos"><b>Contáctenos</b></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container-fluid min-height-contacto" style={{backgroundImage:'url("'+Bg2+'")'}}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="formulario-contacto">
                      <div className="header-formaulario">
                        <p className="p-3 h4"><b>FORMULARIO</b><br/>de contacto</p>
                      </div>
                      <div className="formulario">
                        <form onSubmit={onSubmit}>
                          <input 
                                type="text" 
                                className="input" 
                                name="nombre_completo" 
                                placeholder="Nombre completo*" 
                                required="required" 
                                onChange={onChange}
                                />
                          <input 
                                type="text" 
                                className="input mt-3" 
                                name="email" 
                                placeholder="Correo electronico*" 
                                required="required"
                                onChange={onChange} 
                                />
                          <input 
                                type="text" 
                                className="input mt-3" 
                                name="telefono" 
                                placeholder="Telefono*" 
                                required="required" 
                                onChange={onchange}
                                />
                          <textarea 
                                name="" 
                                className="input mt-3 bg-white" 
                                cols="40" 
                                rows="5" 
                                placeholder="Mensaje y/o comentario"
                                onchange={onchange}
                                required="required"
                                ></textarea>
                          <div class="form-check mt-3">
                            <input 
                                class="form-check-input" 
                                type="checkbox" 
                                value="1" 
                                name="tratamiento_datos" 
                                id="flexCheckDefault"
                                required="required" 
                                onchange={onchange}
                                />
                            <label class="form-check-label text-muted" for="flexCheckDefault" style={{fontSize:'0.5rem',padding:'0'}}>
                              Autorizo el tratamiento de Datos Personales
                            </label>
                          </div>
                          <div className="text-right mt-3">
                            <button type="submit" className="btn btn-primary pl-3 pr-3 pt-1 pb-1 mt-3">
                              Enviar
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 margin-movil">
                    <div className="row">
                      <div className="col-md-4 text-center">
                        <img src={icon3} className="icon-contacto" />
                        <div className="text-contactos mt-3">
                          <h5><b>UBICACIÓN</b></h5>
                          <p className="text-muted h6">Carrera 21 No 14 - 20 Municipio : Pereira.</p>
                        </div>
                      </div>
                      <div className="col-md-4 text-center bordes">
                        <img src={icon2} className="icon-contacto" />
                        <div className="text-contactos mt-3">
                          <h5><b>TELÉFONO</b></h5>
                          <p className="text-muted h6">+57 3117654</p>
                        </div>
                      </div>
                      <div className="col-md-4 text-center">
                        <img src={icon1} className="icon-contacto" />
                        <div className="text-contactos mt-3">
                          <h5><b>CORREO</b></h5>
                          <p className="text-muted h6">info@ingeeniar.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 mt-5">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3975.812526558453!2d-75.69398968604146!3d4.802220096512641!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e38876abcb7facd%3A0x99f8f5d3fe6538d7!2sCra.%2021%20%23%2314-20%2C%20Pereira%2C%20Risaralda!5e0!3m2!1ses!2sco!4v1632596773636!5m2!1ses!2sco" style={{width:"100%",height:"320px",border:0,allowfullscreen:"",loading:"lazy"}}></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
}


export default App
